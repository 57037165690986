import React from 'react';
import PortableText from 'react-portable-text';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	introHeader: {
		lineHeight: 1.0,
		fontWeight: 700,
		[theme.breakpoints.down('xs')]: {
			lineHeight: 1.2,
			textAlign: 'left',
		},
	},
	paragraph: {
		lineHeight: '32px',
		marginTop: '1rem',
		fontWeight: 500,
		[theme.breakpoints.down('xs')]: {
			textAlign: 'left',
		},
	},
}));

export const Intro = ({ intro }) => {
	const classes = useStyles();

	return (
		<Grid
			container
			item
			// xs={12}
			// lg={10}
			style={{ textAlign: 'center' }}>
			<PortableText
				content={intro}
				serializers={{
					h2: ({ children }) => (
						<Typography
							variant='h2'
							color='primary'
							className={classes.introHeader}>
							{children}
						</Typography>
					),
					normal: ({ children }) => (
						<Typography variant='body1' className={classes.paragraph}>
							{children}
						</Typography>
					),
				}}
			/>
		</Grid>
	);
};
