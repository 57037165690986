import React, { useContext } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { BgImage } from 'gbimage-bridge';
import PortableText from 'react-portable-text';

import {
	Button,
	Typography,
	Grid,
	makeStyles,
	useMediaQuery,
} from '@material-ui/core';
import { Link } from 'gatsby';

import FormModalContext from '../../../context/FormModalContext';

const useStyles = makeStyles((theme) => ({
	root: {
		padding: '6rem 0 8rem 0',
		width: '100%',
		[theme.breakpoints.down('lg')]: {
			padding: '4rem',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '4rem 0',
		},
	},
	bgImg: {
		padding: '0px',
		overflow: 'visible',
		borderRadius: '20px',
		'&, &:before, &:after': {
			borderRadius: '20px',
			boxShadow: '0 10px 40px 0px rgba(0,0,0,0.2)',
		},
		[theme.breakpoints.down('lg')]: {
			padding: '1rem 0',
		},
		[theme.breakpoints.down('md')]: {
			padding: '.5rem 0',
			overflow: 'hidden',
		},
	},
	button: {
		background: 'white',
		color: '#002D5C',
		padding: '16px 32px',
		opacity: 1,
		fontSize: '1.1rem',
		fontWeight: 500,
		zIndex: 1000,
		transition: 'all 0.35s ease !important',
		'&:hover': {
			opacity: '0.85 !important',
		},
		[theme.breakpoints.down('md')]: {
			margin: '2rem 0 .5rem',
		},
		[theme.breakpoints.down('sm')]: {
			margin: '1rem 0',
			padding: '24px 36px',
		},
		[theme.breakpoints.down('xs')]: {
			margin: '2rem 0 0 0',
		},
	},
	noBgButton: {
		background: '#2a7abc',
		color: 'white',
		padding: '16px 32px',
		opacity: 1,
		fontSize: '1.1rem',
		fontWeight: 500,
		zIndex: 1000,
		transition: 'all 0.35s ease !important',
		'&:hover': {
			background: '#2a7abc',
			opacity: '0.85 !important',
		},
		[theme.breakpoints.down('md')]: {
			margin: '2rem 0 .5rem',
		},
		[theme.breakpoints.down('sm')]: {
			margin: '1rem 0',
		},
		[theme.breakpoints.down('xs')]: {
			margin: '1rem 0 .5rem',
		},
	},
	content: {
		color: '#FFF',
		[theme.breakpoints.down('md')]: {
			textAlign: 'flex-start',
			padding: '0 25px',
		},
	},
	calloutHeader: {
		fontSize: '2.33rem',
		lineHeight: 1.2,
		color: '#FFF',
		fontWeight: 500,
		padding: '2rem 0 1rem',
		[theme.breakpoints.down('md')]: {
			textAlign: 'flex-start',
			// padding: '25px 0 0 25px',
		},
		[theme.breakpoints.down('xs')]: {
			fontSize: '1.75rem',
			padding: '2rem 0 0 0',
		},
	},
	noBgCalloutHeader: {
		fontSize: '2.25rem',
		lineHeight: 1.2,
		color: theme.workwaveBlue,
		marginBottom: '1rem',
		[theme.breakpoints.down('md')]: {
			textAlign: 'flex-start',
			padding: '15px 0 0 15px',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '2rem',
		},
	},
	calloutSubHeader: {
		color: '#FFF',
		lineHeight: '28px',
		fontSize: '18px',
		fontWeight: 400,
		[theme.breakpoints.down('md')]: {
			textAlign: 'left',
			padding: '0 15px',
		},
	},
	stylized: {
		textDecoration: 'underline',
	},
	imgRight: {
		position: 'absolute',
		[theme.breakpoints.down('md')]: {
			position: 'relative',
		},
	},
}));

//NOTE: THIS COMPONENT IS NO LONGER REUSABLE FOR THE TIME BEING

export const HomeNewsBar = ({
	background,
	header,
	subheader,
	newsBarText,
	newsBarLink,
	newsBarImage,
	internalLink,
	simulations,
}) => {
	const classes = useStyles();

	const { handleModalClick } = useContext(FormModalContext);

	const lg = useMediaQuery('(max-width: 1260px)');

	return (
		<div className={classes.root}>
			<BgImage
				style={{
					borderRadius: '20px',
					boxShadow: '0 10px 40px 0px rgba(0,0,0,0.2)',
					// backgroundSize: 'cover',
					backgroundPosition: 'bottom',
				}}
				className={classes.bgImg}
				image={background?.asset?.gatsbyImageData}>
				<Grid
					container
					direction='row'
					justifyContent={lg ? 'center' : 'space-evenly'}
					alignItems='center'
					style={{ height: '100%', borderRadius: '20px' }}>
					<Grid
						item
						container
						direction='column'
						xs={12}
						md={10}
						lg={!background ? 7 : 6}
						justifyContent={lg ? 'center' : 'space-evenly'}
						alignItems={lg ? 'center' : 'flex-start'}
						style={{ height: '100%', paddingBottom: '1rem' }}>
						{typeof header !== 'string' ? (
							<PortableText
								content={header}
								serializers={{
									h3: ({ children }) => (
										<Typography
											variant='h3'
											className={
												!background
													? classes.noBgCalloutHeader
													: classes.calloutHeader
											}
											style={{ textAlign: lg && 'center' }}>
											{children}
										</Typography>
									),
									h4: ({ children }) => (
										<Typography variant='h4' style={{ color: 'white' }}>
											{children}
										</Typography>
									),
									strong: ({ children }) => (
										<span style={{ fontWeight: 700 }}>{children}</span>
									),
									em: ({ children }) => (
										<span className={classes.stylized}>{children}</span>
									),
								}}
							/>
						) : (
							<Typography
								variant='h2'
								className={
									!background
										? classes.noBgCalloutHeader
										: classes.calloutHeader
								}>
								{header}
							</Typography>
						)}
						{newsBarLink ? (
							internalLink ? (
								<Link to={`/${newsBarLink}`} style={{ textDecoration: 'none' }}>
									<Button
										variant='contained'
										size='large'
										className={
											!background ? classes.noBgButton : classes.button
										}>
										{newsBarText}
									</Button>
								</Link>
							) : (
								<a
									href={newsBarLink}
									target='_blank' aria-label="Opens in a new tab"
									style={{ textDecoration: 'none' }}>
									<Button
										variant='contained'
										size='large'
										className={
											!background ? classes.noBgButton : classes.button
										}>
										{newsBarText}
									</Button>
								</a>
							)
						) : (
							<Button
								variant='contained'
								size='large'
								className={!background ? classes.noBgButton : classes.button}
								onClick={handleModalClick}>
								{newsBarText}
							</Button>
						)}

						{/* <Typography
							variant='body1'
							className={classes.calloutSubHeader}
							style={{ color: !background ? '#4B5B69' : null }}>
							{subheader}
						</Typography> */}
					</Grid>
					<Grid
						item
						container
						alignItems='center'
						justifyContent='center'
						xs={12}
						lg={5}
						style={{ height: '100%' }}>
						<GatsbyImage
							image={newsBarImage?.asset?.gatsbyImageData}
							alt='img-right'
							className={classes.imgRight}
						/>
					</Grid>
				</Grid>
			</BgImage>
		</div>
	);
};