import React, { useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PortableText from 'react-portable-text';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link, navigate, Script } from 'gatsby';
import loadable from '@loadable/component';

// import ReactPlayer from 'react-player/lazy';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Container, useMediaQuery } from '@material-ui/core';

import { WaveUpHero } from '../../WaveSVGs/WaveUpHero';
import { ProductFeatureSearch } from './ProductFeatureSearch';
// import home from '../../../assets/home-video.mp4';
// import { Video } from './Video';

const Video = loadable(() => import('./Video'));

const useStyles = makeStyles((theme) => ({
  '@keyframes pulseBorder': {
    '0%': {
      transform: 'translateX(-50%) translateY(-50%) translateZ(0) scale(1)',
      opacity: '1',
    },
    '100%': {
      transform: 'translateX(-50%) translateY(-50%) translateZ(0) scale(1.5)',
      opacity: '0',
    },
  },

  heroCornerSVG: {
    position: 'absolute',
    width: '800px',
    zIndex: '0',
    height: 'auto',
    bottom: '150px',
    left: '-200px',
    [theme.breakpoints.down('xl')]: {
      bottom: '-270px',
    },
    [theme.breakpoints.down('lg')]: {
      bottom: '-300px',
    },
    [theme.breakpoints.down('md')]: {
      bottom: '-235px',
      width: '600px',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },

  heroPills: {
    margin: '0',
    marginBottom: '0px !important',
    marginTop: '15px !important',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '695px',
    maxWidth: '100%',
    [theme.breakpoints.down('md')]: {
      width: '530px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },

  pillsText: {
    textAlign: 'center',
    width: '100%',
    color: '#002D5C',
    fontWeight: 500,
    padding: '0 10px',
    position: 'relative',
    lineHeight: '1.1',
    fontSize: '.8rem',
    [theme.breakpoints.down('md')]: {
      padding: '0 5px',
      fontSize: '.7rem',
    },
  },

  pillsTextAll: {
    textAlign: 'center',
    width: '100%',
    color: '#002D5C',
    fontWeight: 500,
    padding: '0 10px',
    position: 'relative',
    lineHeight: '1.1',
    fontSize: '.8rem',
    [theme.breakpoints.down('md')]: {
      padding: '0 5px',
      fontSize: '.7rem',
    },
  },

  heroPill: {
    maxWidth: '19%',
    padding: '0px',
    paddingTop: '0px',
    borderRadius: '8px',

    [theme.breakpoints.down('md')]: {
      minWidth: '24%',
    },

    [theme.breakpoints.down('sm')]: {
      width: '50%',
      maxWidth: '50%',
      margin: '0 auto',
      marginBottom: '16px',
    },

    '&:nth-last-of-type(1)': {
      fontSize: '.75rem',
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
      [theme.breakpoints.down('sm')]: {
        display: 'block',
      },
    },

    '&:nth-child(even)': {
      padding: '0px',
      [theme.breakpoints.down('sm')]: {
        paddingTop: '0px',
        paddingLeft: '8px',
        paddingRight: '0px',
      },
    },
    '&:nth-child(odd)': {
      padding: '0px',
      [theme.breakpoints.down('sm')]: {
        paddingTop: '0px',
        paddingLeft: '0px',
        paddingRight: '8px',
      },
    },
  },

  root: {
    position: 'relative',
    width: '100%',
    padding: '6rem 0',
    maxWidth: '100vw',
    overflow: 'hidden',

    [theme.breakpoints.down('xs')]: {
      padding: '3rem 0',
    },
  },

  bgBlueLight: {
    width: '230px',
    height: '230px',
    background: '#002D5C',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    borderRadius: '50%',
    opacity: '0.75',

    [theme.breakpoints.down('md')]: {
      width: '180px',
      height: '180px',
    },

    [theme.breakpoints.down('sm')]: {
      background: 'rgba(255,255,255,0)',
      top: 0,
      left: 0,
      borderRadius: '0px',
      margin: '0px',
    },
  },

  playButton: {
    height: '100px',
    width: '100px',
    background: '#fff',
    color: '#002d5c',
    fontSize: '1.5rem',
    borderRadius: '50%',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
    opacity: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%)',
    cursor: 'pointer',
    zIndex: 5,

    '&::before': {
      content: `''`,
      position: 'absolute',
      zIndex: '-1',
      left: '50%',
      top: '50%',
      transform: 'translateX(-50%) translateY(-50%)',
      display: 'block',
      width: '100px',
      height: '100px',
      background: '#fff',
      borderRadius: '50%',
    },

    '&:hover': {
      '& $playButtonEffect': { transform: 'scale(1.1)' },
      '&::before': {
        animation: '$pulseBorder 1500ms ease-out infinite',
      },
    },
    // [theme.breakpoints.down('lg')]: {
    //   top: '43%',
    //   left: '109%',
    // },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },

  heroVideoBg: {
    position: 'absolute',
    zIndex: 3,
    top: '37%',
    left: '92%',
    width: '650px',
    height: '650px',
    transform: 'translateX(-50%) translateY(-50%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    alignContent: 'flex-end',
    boxShadow: '0 0 0 60px #2a7abc, 0 0 0 120px #002d5c',
    borderRadius: '50%',
    overflow: 'auto',

    [theme.breakpoints.down('xl')]: {
      top: '37%',
      left: '90%',
      width: '650px',
      height: '650px',
    },
    [theme.breakpoints.down('1685')]: {
      top: '37%',
      left: '90%',
      width: '650px',
      height: '650px',
    },
    [theme.breakpoints.down('md')]: {
      left: '92%',
      top: '40%',
      width: '530px',
      height: '530px',
      boxShadow: '0 0 0 40px #2a7abc, 0 0 0 80px #002d5c',
    },

    // [theme.breakpoints.between(1336, 1575)]: {
    //   width: "510px",
    // },
    // [theme.breakpoints.between(1326, 1336)]: {
    //   width: "500px",
    // },
    // [theme.breakpoints.between(1280, 1326)]: {
    //   width: "465px",
    // },
    // [theme.breakpoints.between(1121, 1280)]: {
    //   width: "510px",
    // },
    // [theme.breakpoints.between(1000, 1121)]: {
    //   width: "408px",
    //   height: "635px",
    // },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  heroImgBg: {
    width: '100%',
    height: '100%',
    zIndex: -2,
    [theme.breakpoints.down('sm')]: {
      left: 0,
      top: 0,
    },
  },
  mobileHeroImgBg: {
    // display: 'inline-block',
    position: 'absolute',
    // top: -80,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    zIndex: -2,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  heroContent: {
    textAlign: 'left',
    position: 'relative',
    left: '0',
  },
  text: {
    color: theme.workwaveBlue,
    lineHeight: '.7',
  },
  imgCont: {
    padding: '2rem 0',
    maxWidth: '100vw',
    overflowX: 'hidden',
    background:
      'linear-gradient(0deg, rgba(248, 251, 255, 0.8), rgba(248, 251, 255, 0.8)), linear-gradient(0deg, #F8FBFF, #F8FBFF), #F8FBFF',

    [theme.breakpoints.down('xs')]: {
      paddingTop: '2rem',
      paddingBottom: '1rem',
    },
  },
  imgRight: {
    marginLeft: '-10rem',
    [theme.breakpoints.down('lg')]: {
      marginLeft: '-9rem',
      height: '560px',
    },
    // [theme.breakpoints.down('md')]: {
    // 	marginLeft: '-4rem',
    // 	height: '380px',
    // },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '-8rem',
      height: '300px',
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: '0',
      height: '260px',
    },
  },
  pills: {
    border: '1px solid #E5EAF4',
    borderRadius: '8px',
    background: '#FFFFFF',
    display: 'flex',
    alignItems: 'center',
    transition: 'all .5s',
    '&:hover': {
      transform: 'scale(1.05)',
    },
  },
  heroHeader: {
    lineHeight: '4.1875rem',
    letterSpacing: '.2px',
    fontWeight: 700,
    fontSize: '3.9rem',
    color: theme.workwaveBlue,
    maxWidth: '700px',

    [theme.breakpoints.down('md')]: {
      fontSize: '3rem',
      lineHeight: '60px',
      textAlign: 'left',
      maxWidth: '550px',
    },
    [theme.breakpoints.down('sm')]: {
      lineHeight: '45px',
      fontSize: '2.5rem',
      marginLeft: '0',
      maxWidth: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '2rem',
    },
  },
  heroSubheader: {
    color: theme.white,
    letterSpacing: '.2px',
    lineHeight: '53px',
    fontWeight: 700,
    fontSize: '3.7813rem',
    marginTop: '10px',
    marginBottom: '16px',
    marginLeft: '5px',
    [theme.breakpoints.down('md')]: {
      marginLeft: '-6px',
      textAlign: 'center',
      fontSize: '2.625rem',
      lineHeight: '40px',
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0',
    },
  },
  heroBodyContent: {
    lineHeight: 1.25,
    fontSize: '1.8rem',
    color: '#4B5B69',
    fontWeight: 700,
    textAlign: 'left',
    marginTop: '0px',
    letterSpacing: '0.0125rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.6rem',
    },
    [theme.breakpoints.down('sm')]: {
      // fontSize: '1.375rem',
      fontWeight: 600,
    },
    [theme.breakpoints.down('sm')]: {
      // fontSize: '1.25rem',
    },
  },

  heroSearchInput: {
    display: 'none',
  },

  playerWrapper: {
    position: 'relative',
    paddingTop: '56.25%',
    minHeight: '100%',
    minWidth: '100%',
    border: 'none',
    marginTop: '0',
    zIndex: 10,
    borderRadius: '20px',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      marginBottom: '-50px',
    },
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  reactPlayer: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  waveEdge: {
    position: ' absolute',
    left: 0,
    bottom: 0,

    //ignore the css below. It's just to create a css triangle but we need an SVG for this shape because of the "curve" this triangle has in the mockup.
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '300px 0 0 400px',
    borderColor: 'transparent transparent transparent #2F7FC1',
    [theme.breakpoints.down('lg')]: {
      borderWidth: '240px 0 0 400px',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

export const Hero = ({
  hero,
  productFeatures,
  homeHeroVideo,
  setHeroModalOpen,
}) => {
  const classes = useStyles();
  const searchRef = useRef(null);
  const [selectedSlug, setSelectedSlug] = useState(null);

  //search term change handler
  const handleChange = (e, value) => {
    const selected = productFeatures.edges.filter(
      (feature) => feature.node.title === value
    );
    if (selected.length) {
      setSelectedSlug(selected[0].node.slug.current);
      navigate(`/${selected[0].node.slug.current}`);
    } else {
      setSelectedSlug(null);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    selectedSlug && navigate(`/${selectedSlug}`);
  };

  const lg = useMediaQuery('(max-width: 1279px)');
  const md = useMediaQuery('(max-width: 960px)');

  return (
    <>
      <Grid
        container
        direction="row"
        alignItems="center"
        className={classes.root}
      >
        <section className={classes.heroVideoBg}>
          <img
            src={hero.videoImage?.asset?.gatsbyImageData.images.fallback.src}
            alt="home-hero"
            className={classes.heroImgBg}
          />
					{!md && (
          <Video          
            url="https://documents.workwave.com/pestpac/videos/one_true_loop_1080x1080.mp4"
            playing={true}
            loop={true}
            muted={true}
            width="100%"
            height="100%"
          />
					)}
          <div className={classes.bgBlueLight} />
          <div
            className={classes.playButton}
            role="button"
            tabIndex={0}
            onClick={() => setHeroModalOpen(true)}
          >
            <FontAwesomeIcon icon={['fas', 'play']} size="lg" />
          </div>
        </section>
        {md && (
 
					<img
					src={hero.mobileBackground?.asset?.gatsbyImageData.images.fallback.src}
					alt="home-hero"
          className={classes.mobileHeroImgBg}
				/>
        )}
        <Container style={{ position: 'inherit', zIndex: '2' }}>
          <Grid
            container
            item
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            className={classes.heroContent}
            xs={12}
            md={8}
          >
            {hero._rawContent.map((content, index) => (
              <PortableText
                key={index}
                content={content}
                className={classes.text}
                serializers={{
                  h1: ({ children }) => (
                    <Typography variant="h2" className={classes.heroHeader}>
                      {children}
                    </Typography>
                  ),
                  strong: ({ children }) => (
                    <span style={{ color: '#2A7ABC' }}>{children}</span>
                  ),
                  h4: ({ children }) => (
                    <Typography variant="h4" className={classes.heroSubheader}>
                      {children}
                    </Typography>
                  ),
                  normal: ({ children }) => {
                    return (
                      <Typography
                        variant="body1"
                        component="h1"
                        className={classes.heroBodyContent}
                        style={{
                          lineHeight: md ? 1.2 : '38px',
                        }}
                      >
                        {children}
                      </Typography>
                    );
                  },
                }}
              />
            ))}{' '}
          </Grid>
          <Grid
            container
            item
            direction="column"
            justifyContent="center"
            alignItems={md ? 'center' : 'flex-start'}
            // className={classes.heroContent}
            xs={12}
            md={6}
            lg={8}
          >
            <ProductFeatureSearch
              className={classes.heroSearchInput}
              productFeatures={productFeatures}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              searchRef={searchRef}
            />
          </Grid>
          <Grid
            item
            container
            direction="row"
            justifyContent={md ? 'center' : 'flex-start'}
            spacing={2}
            xs={12}
            md={9}
            style={{ marginTop: '.5rem' }}
            className={classes.heroPills}
          >
            {productFeatures.edges
              .filter((feature) => feature.node.primary)
              .sort((a, b) => a.node.title.localeCompare(b.node.title))
              .map((feature, index) => (
                <Grid
                  item
                  key={index}
                  style={{ position: 'relative' }}
                  className={classes.heroPill}
                >
                  <Link
                    to={`/${feature.node.slug.current}`}
                    style={{ textDecoration: 'none', position: 'inherit' }}
                  >
                    <div className={classes.pills}>
                      <FontAwesomeIcon
                        icon={['fad', feature.node.marketingLinkIcon]}
                        style={{
                          height: '35px',
                          width: '35px',
                          border: `1px solid #E5EAF4`,
                          background: '#E5EAF4',
                          padding: '6px',
                          color:
                            feature.node.linkIconColor?.hexValue ?? '#002D5C',
                          borderTopLeftRadius: '6px',
                          borderBottomLeftRadius: '6px',
                        }}
                      />
                      <Typography
                        variant="h3"
                        className={classes.pillsText}
                      >
                        {feature.node.homeHeroPillTitle}
                      </Typography>
                    </div>
                  </Link>
                </Grid>
              ))}
            <Grid
              item
              style={{ position: 'relative' }}
              className={classes.heroPill}
            >
              <Link
                to={`/why-pestpac/#feature`}
                style={{ textDecoration: 'none', position: 'inherit' }}
              >
                <div className={classes.pills}>
                  <FontAwesomeIcon
                    icon={['fad', 'circle-plus']}
                    style={{
                      height: '35px',
                      width: '35px',
                      border: `1px solid #E5EAF4`,
                      background: '#E5EAF4',
                      padding: '6px',
                      color: '#002D5C',
                      borderTopLeftRadius: '6px',
                      borderBottomLeftRadius: '6px',
                    }}
                  />
                  <Typography
                    variant="h3"
                    className={classes.pillsTextAll}
                  >
                    All Features
                  </Typography>
                </div>
              </Link>
            </Grid>
          </Grid>
          {md && (
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              item
              xs
            >
              <div className={classes.playerWrapper}>
                <Video
                  url={homeHeroVideo}
                  className={classes.reactPlayer}
                  height="100%"
                  width="100%"
                  controls={true}
                />
              </div>
            </Grid>
          )}
        </Container>

        <svg
          className={classes.heroCornerSVG}
          viewBox="0 0 441 406"
          role="presentation"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_426_80)">
            <path
              d="M440.609 352.881C433.949 353.121 427.39 354.321 420.79 355.101C406.5 356.781 392.22 358.611 377.94 360.381C362.42 362.301 346.9 364.241 331.38 366.161C317.26 367.911 303.14 369.641 289.02 371.381C274.9 373.121 260.78 374.861 246.66 376.611C232.38 378.371 218.09 380.141 203.81 381.901C190.02 383.601 176.06 384.461 162.48 387.191C144.15 390.881 125.65 393.341 107.26 396.531C89.3995 399.631 71.4695 402.351 53.5695 405.281C51.9295 405.551 51.2595 405.231 50.9795 403.431C48.4395 387.241 45.7995 371.081 43.1795 354.901C39.7395 333.641 36.2895 312.381 32.8595 291.111C29.7895 272.061 26.7495 253.011 23.6795 233.961C20.5895 214.751 17.4695 195.541 14.3695 176.331C10.9695 155.231 7.57953 134.131 4.18953 113.021C2.81953 104.481 1.48952 95.9311 0.069525 87.4011C-0.150475 86.0811 -0.030475 85.5811 1.47952 85.3411C22.0995 82.0811 42.7095 78.7411 63.3195 75.4111C73.5795 73.7511 83.8395 72.0811 94.0995 70.4111C98.5295 69.7211 102.98 69.1311 107.38 68.2911C108.82 68.0111 109.42 68.4311 110.15 69.5411C115.02 76.9211 120.63 83.7311 126.73 90.1111C141 105.041 157.09 117.781 174.13 129.351C196.28 144.391 219.71 157.161 243.98 168.411C272.56 181.651 302.05 192.481 332.27 201.311C357.26 208.611 382.62 214.371 408.28 218.771C419 220.611 429.759 222.261 440.579 223.411V352.881H440.609Z"
              fill="#4179B6"
            />
            <path
              d="M440.61 223.412C429.79 222.262 419.03 220.612 408.31 218.772C382.65 214.372 357.3 208.622 332.3 201.312C302.08 192.482 272.6 181.662 244.01 168.412C219.73 157.162 196.3 144.392 174.16 129.352C157.12 117.782 141.03 105.042 126.76 90.1117C120.66 83.7217 115.05 76.9217 110.18 69.5417C109.45 68.4317 108.86 68.0117 107.41 68.2917C103.01 69.1417 98.5603 69.7217 94.1303 70.4117C93.3503 47.0317 92.5703 23.6617 91.7803 0.0117188C92.9203 0.911719 93.1403 2.15172 93.6603 3.16172C101.41 18.0317 111.57 31.1717 122.92 43.4117C152.75 75.6217 187.79 101.242 225.44 123.402C286.52 159.342 351.95 184.202 421.09 199.452C427.58 200.882 434.11 202.102 440.62 203.422V223.422L440.61 223.412Z"
              fill="#0D2950"
            />
          </g>
          <defs>
            <clipPath id="clip0_426_80">
              <rect width="440.61" height="405.36" fill="white" />
            </clipPath>
          </defs>
        </svg>

        <WaveUpHero
          height="213"
          width="100%"
          fill="white"
          home={true}
          style={{ zIndex: '2' }}
        />
      </Grid>
    </>
  );
};
