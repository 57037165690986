import React from 'react';
import loadable from '@loadable/component';

import { Container, useMediaQuery, makeStyles } from '@material-ui/core';

import { Intro } from './Intro';
import { ProductFeatureBody } from '../../ProductFeature/ProductFeatureBody';
import { WaveUpSVG } from '../../WaveSVGs/WaveUpSVG';
import { Testimonials } from '../../Testimonials';
import { CustomerLogos } from '../../CustomerLogos';

const useStyles = makeStyles((theme) => ({
	centerFeatures: {
		padding: '4rem 0',
		[theme.breakpoints.down('sm')]: {
			padding: '2rem 0',
		},
	},
	testimonialBackground: {
		backgroundSize: 'cover',
		height: '600px',
		width: '99vw',
		maxWidth: '100%',
		[theme.breakpoints.down('md')]: {
			width: '100vw',
			height: '500px',
		},
		[theme.breakpoints.down('sm')]: {
			height: '400px',
		},
	},
	testimonialContainer: {
		marginTop: '-35rem',
		[theme.breakpoints.down('md')]: {
			marginTop: '-30rem',
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: '-25rem',
		},
	},
	testimonialTitle: {
		color: theme.white,
		fontWeight: 700,
		padding: '2rem 0',
	},
}));

export const HomeBody = ({ intro, home, body }) => {
	const classes = useStyles();

	return (
		<>
			<div>
				<Container>
					<Intro intro={intro} />
				</Container>
			</div>
			<ProductFeatureBody body={body} home={home} />
			<>
				<div
					className={classes.testimonialBackground}
					style={{
						backgroundImage: `url(${body.testimonialBackground?.asset.gatsbyImageData.images.fallback.src})`,
					}}
				/>
				<WaveUpSVG fill='#FFF' />
				<div className={classes.testimonialContainer}>
					<Testimonials
						header={body.testimonialHeader}
						testimonials={body.testimonials}
						background
					/>
				</div>
			</>
			<Container>
				<CustomerLogos
					customerLogosArray={body.customerLogos}
					customerLogosHeader={body.customerLogosHeader}
				/>
			</Container>
		</>
	);
};
